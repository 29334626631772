import { Logger } from '@/logger'
import { byKey, type Remote } from './defaults'

export const logger = new Logger('tria/remote-config')

/**
 * Find and print debug diff between two remote configs
 */
export function diff(
  a: Partial<Remote>,
  b: Partial<Remote>
): Partial<Remote> | null {
  const changed: Partial<Remote> = {}
  let hasChanges = false

  // "smart" equality check function
  // tries to compare slightly different values, for example strings with json
  const eq = (x: unknown, y: unknown) => {
    if (typeof x !== typeof y) {
      return false
    }

    if (typeof x === 'number' && isNaN(x) && isNaN(y as number)) {
      return true
    }

    if (typeof x === 'string' && typeof y === 'string') {
      const xo =
        (x.includes('{') && x.includes('}')) ||
        (x.includes('[') && x.includes(']'))
      const yo =
        (y.includes('{') && y.includes('}')) ||
        (y.includes('[') && y.includes(']'))
      if (xo && yo) {
        try {
          return JSON.stringify(JSON.parse(x)) === JSON.stringify(JSON.parse(y))
        } catch (ignore) {
          // ignore
        }
      }
    }

    if (typeof x === 'object' && typeof y === 'object') {
      try {
        return JSON.stringify(x) === JSON.stringify(y)
      } catch (ignore) {
        // ignore
      }
    }

    return x === y
  }

  let key: keyof Remote
  for (key in a) {
    if (Object.prototype.hasOwnProperty.call(b, key) && byKey(key)) {
      const adesc = getPropertyDescriptor(a, key)
      const bdesc = getPropertyDescriptor(b, key)
      if (!eq(adesc?.value, bdesc?.value)) {
        if (bdesc?.writable === false) {
          // add non-writable property -> this is debug override
          logger.debug(
            `🚩 %c${key}%c adding overridden value`,
            'color:lightblue',
            'color:red'
          )
          Object.defineProperty(changed, key, {
            value: b[key],
            writable: false,
            enumerable: true,
            configurable: true,
          })
        } else if (adesc?.writable === false) {
          // if original property is not writable -> skip update, because this is debug override
          logger.debug(
            `🚩 %c${key}%c skipping update for overridden value`,
            'color:lightblue',
            'color:red'
          )
          continue
        } else {
          // just update value
          changed[key] = b[key] as any // eslint-disable-line @typescript-eslint/no-explicit-any
        }

        hasChanges = true
        logger.debug(
          `🚩 %c${key}%c updated from %c${typeof a[key] === 'string' ? a[key] || ' ' : a[key]}%c => %c${b[key]}`,
          'color:lightblue',
          'color:inherit',
          'color:gray;text-decoration:line-through',
          'color:inherit;text-decoration:inherit',
          'color:lightgreen'
        )
      }
    }
  }

  if (!hasChanges) {
    logger.debug('🚩 %cnothing changed', 'color:gray')
  }

  return hasChanges ? changed : null
}

/**
 * Get property descriptor from prototype chain,
 * This is different from `Object.getOwnPropertyDescriptor`
 * because it will return descriptor from prototype chain
 * if property is not found on the object itself.
 */
function getPropertyDescriptor(obj: object, propName: string) {
  let current = obj

  while (current !== null) {
    const descriptor = Object.getOwnPropertyDescriptor(current, propName)
    if (descriptor) {
      return descriptor
    }
    current = Object.getPrototypeOf(current)
  }

  return undefined
}
