import { type Remote } from './defaults'
import { $config, update } from './model'

//
// debug setters for remote config
//

// eslint-disable-next-line effector/no-watch
$config.watch((config) => {
  const setters = {}

  // we need ALL properties from whole prototype chain, not only own ones
  // eslint-disable-next-line guard-for-in
  for (const key in config) {
    Object.defineProperty(setters, key, {
      set(value: unknown) {
        // update remote config with frozen object
        // this is a little hack to prevent changes
        update(Object.freeze({ [key]: value }))
      },
      get() {
        return config[key as keyof Remote]
      },
    })
  }

  window.DEBUG_REMOTE = setters
})
