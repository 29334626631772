// organize-imports-ignore
// import './wdyr'
import './debug'
import '!/api'
import '!/i18n'
import 'core-js/stable/array/to-spliced'
import history from 'history/browser'
import { createRoot, type Root } from 'react-dom/client'
import { FiberProvider } from 'its-fine'
import { App, Bootstrap, model as app, versions } from '~/app'
import { env, config } from '@/config'
import { flushSync } from 'react-dom'

const container: HTMLElement = document.getElementById('root') as HTMLElement
const root: Root = createRoot(container)

// render bootstrap loader before router resolves any route
root.render(<Bootstrap />)

// Render and re-render application on each location change
app.onLocationChange(({ location, route }) => {
  flushSync(() => {
    root.render(
      <FiberProvider>
        <App location={location} route={route} />
      </FiberProvider>
    )
  })
})

// init application
//  -> subscribe to history events
//  -> add global onclick handler for navigation
app.init({
  history,
  container,
  routes: {
    load: () => import('~/routes'),
  },
})

// export versions, environment variables and firebase remote config
// for debug and information purposes
window.VERSIONS = versions
window.ENV = env
window.REMOTE = config
