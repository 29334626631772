export * from './index.h'
export * from './lib'
export * as model from './model'
import { v4 as uuidv4 } from 'uuid'

//
// test sse events without actual sse events
//

import { DataMessageType, EVENT } from './index.h'
import { message } from './model'
const ssevent =
  (type: DataMessageType) => (id: number, id1?: number, id2?: number) =>
    message({
      event: EVENT.data,
      id: uuidv4(),
      data: {
        type,
        itemId: id,
        metadata: {
          tvShowId: id1,
          seasonId: id2,
        },
      },
    })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sse: any = ((window as any).sse = {})
Object.entries(DataMessageType).forEach(([key, value]) => {
  sse[key] = ssevent(value)
})
