import type { AppearanceRow } from '@setplex/tria-api'
import type { ApiAnswerSliceDtoRowDto, RowDto } from '@setplex/wbs-api-types'
import * as v from 'valibot'
import { response, slice, type ApiType } from '../../../shared/schemas'
import { FALLBACK_STYLE, StyleSchema } from './styles'

export const FALLBACK_ROW_STYLE = {
  posterSize: 'M',
  posterOrientation: 'VERTICAL',
  background: FALLBACK_STYLE,
} as const

export const WbsApiResourceSchema = v.pipe(
  v.string(),
  v.rawTransform(({ dataset, addIssue, NEVER }) => {
    // remove '/api' prefix (if any) from given relative path,
    // because HttpClient uses hardcoded base url '/wbs/web/api' (already has '/api' part in it)
    let uri = dataset.value.replace(/^\/api/, '')
    try {
      // create URL object with dummy base url to parse given relative path as absolute URL
      // do not use `window.location.origin` as base URL in case we will use this schema in node.js
      const url = new URL(uri, 'http://dumb')
      return {
        path: url.pathname,
        search: url.searchParams,
      }
    } catch (e) {
      addIssue({ message: `Invalid API resource: ${e}` })
      return NEVER
    }
  })
)

export const RowStyleSchema = v.object({
  posterSize: v.nullish(v.picklist(['S', 'M', 'L']), 'M'),
  posterOrientation: v.nullish(
    v.picklist(['HORIZONTAL', 'VERTICAL']),
    'VERTICAL'
  ),
  background: v.nullish(StyleSchema, FALLBACK_STYLE),
})

export const RowSchema = v.object({
  id: v.string(),
  title: v.string(),
  localizedTitleKey: v.nullish(v.string(), () => undefined),
  description: v.nullish(v.string(), () => undefined),
  refreshable: v.nullish(v.boolean(), false),
  contentType: v.picklist([
    'LIVE_EVENT',
    'MIXED',
    'PARTNER_PRODUCT',
    'TV_BUNDLE',
    'TV_CHANNEL',
    'TV_SHOW_BUNDLE',
    'TV_SHOW',
    'VOD_BUNDLE',
    'VOD',
  ]),
  resource: WbsApiResourceSchema,
  paged: v.nullish(v.boolean(), false),
  style: v.nullish(RowStyleSchema, FALLBACK_ROW_STYLE),
}) satisfies ApiType<RowDto, AppearanceRow>

export const RowsPageResponseSchema = response(
  slice(RowSchema)
) satisfies ApiType<ApiAnswerSliceDtoRowDto, any>
