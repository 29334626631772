import type { Meter, MeterProvider } from '@opentelemetry/api'
import { createEffect, createStore } from 'effector'
import type { HttpClientMetrics } from './index.h'

export const $metrics = createStore<HttpClientMetrics | null>(null)

export const getMeterFx = createEffect((provider: MeterProvider): Meter => {
  return provider.getMeter('http-metrics', '1')
})

// prettier-ignore
export const createMetricsFx = createEffect((meter: Meter): HttpClientMetrics => {
  return {
    http_client_requests_count: meter.createCounter('http_client_requests_count', {
      description: 'HTTP requests count',
    }),

    http_client_request_body_size: meter.createHistogram('http_client_request_body_size', {
      description: 'Size of HTTP client request bodies',
      unit: 'By', // bytes
    }),

    http_client_requests_fails_count: meter.createCounter('http_client_requests_fails_count', {
      description: 'Failed HTTP requests count',
    }),

    http_client_response_count: meter.createCounter('http_client_response_count', {
      description: 'HTTP responses count',
    }),

    http_client_response_body_size: meter.createHistogram('http_client_response_body_size', {
      description: 'Size of HTTP client response bodies',
      unit: 'By', // bytes
    }),

    http_client_request_duration: meter.createHistogram('http_client_request_duration', {
      description: 'Duration of HTTP client requests',
      unit: 's', // seconds
      advice: {
        explicitBucketBoundaries: [0.005, 0.01, 0.025, 0.05, 0.075, 0.1, 0.25, 0.5, 0.75, 1, 2.5, 5, 7.5, 10],
      }
    }),

    http_client_dns_duration: meter.createHistogram('http_client_dns_duration', {
      description: 'Duration of DNS names lookup',
      unit: 'ms', // milliseconds
      advice: {
        explicitBucketBoundaries: [1, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000],
      },
    }),

    http_client_connection_duration: meter.createHistogram('http_client_connection_duration', {
      description: 'Duration of HTTP connection',
      unit: 'ms', // milliseconds
      advice: {
        explicitBucketBoundaries: [1, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000],
      },
    }),

    http_client_request_send_duration: meter.createHistogram('http_client_request_send_duration', {
      description: 'Duration of HTTP request send',
      unit: 'ms', // milliseconds
      advice: {
        explicitBucketBoundaries: [1, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000],
      },
    }),

    http_client_response_receive_duration: meter.createHistogram('http_client_response_receive_duration', {
      description: 'Duration of HTTP response receive',
      unit: 'ms', // milliseconds
      advice: {
        explicitBucketBoundaries: [1, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000],
      },
    }),
  }
})
