import { T } from '@/helpers'
import { A, D, pipe } from '@mobily/ts-belt'
import { getAll } from '@setplex/firebase-remote-config'
import { createEvent, createStore, sample, type Store } from 'effector'
import { and, delay, not } from 'patronum'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import {
  asType,
  byKey,
  defaults,
  type Remote,
  type RemoteKeys,
} from './defaults'
import { MAX_REMOTE_CONFIG_AWAIT_TIME } from './index.h'
import { diff } from './lib'

const setReady = createEvent()
export const $ready = createStore<boolean>(false).on(setReady, T)

// firebase remote config store
export const update = createEvent<Partial<Remote>>()
export const $config = createStore<Remote>(defaults).on(
  update,
  (previous, updated) => {
    // compare two configs and find diff
    const changed = diff(previous, updated)

    // create a new config with changed values,
    // while preserving previous values in object prototype chain,
    // or returning previous config if nothing changed to prevent unnecessary updates
    return changed
      ? Object.create(previous, Object.getOwnPropertyDescriptors(changed))
      : previous
  }
)

// firebase remote config single key getter
export const get = <K extends RemoteKeys>(
  name: K,
  def: NonNullable<Remote[K]> = defaults[name]
): Store<NonNullable<Remote[K]>> => $config.map((remote) => remote[name] ?? def)

// set firebase remote config defaults
sample({
  clock: firebase.$ready,
  filter: Boolean,
  fn: () => defaults,
  target: firebase.remote.setDefaults,
})

// fetch and activate on firebase readiness
sample({
  clock: firebase.$ready,
  filter: and(firebase.$ready, not(firebase.remote.fetchAndActivateFx.pending)),
  target: firebase.remote.fetchAndActivateFx,
})

// convert firebase remote config to local config
sample({
  clock: firebase.remote.fetchAndActivateFx.done,
  source: firebase.remote.$firebaseConfig,
  filter: Boolean,
  fn: (config) =>
    pipe(
      config,
      getAll,
      D.filterWithKey(byKey),
      D.toPairs,
      A.map(([key, value]) => [key, asType(key, value!)] as const),
      D.fromPairs
    ) as Remote,
  target: update,
})

// set ready after remote config is loaded or failed to load
sample({
  clock: firebase.remote.fetchAndActivateFx.finally,
  target: setReady,
})

// set ready after some timeout, if remote config is not loaded
delay({
  source: firebase.remote.fetchAndActivateFx,
  timeout: MAX_REMOTE_CONFIG_AWAIT_TIME,
  target: setReady,
})

//
// register stores and events
//

// register($, '$config/remote')
