import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { runtime, version } from '~/app/environment'
import { clip } from '~/shared/helpers'
import { DATE_FORMATS } from '~/shared/helpers/date'

export function getDescriptionUrl(fromUrl?: string | null, pathname?: string) {
  const isEpgPage = pathname?.includes('/epg')
  return fromUrl && !isEpgPage ? fromUrl : window.location.href
}

interface SetUrlMacrosToParamsParams {
  url: string
  nonce?: string
}

interface Placeholders {
  [key: string]: (value: string | undefined, key: string) => string | undefined
}

const deviceTypeMap: { [key: string]: number } = {
  desktop: 1,
  mobile: 2,
  tablet: 3,
  smarttv: 5,
}

const mapUaDeviceTypeToNumber = (type?: string): number | string => {
  return deviceTypeMap[type as string] ?? ''
}

const osMap: { [key: string]: number } = {
  'Windows': 1,
  'Windows Phone': 1,
  'Windows Mobile': 1,
  'Mac OS': 2,
  'Linux': 3,
  'Ubuntu': 3,
  'Debian': 3,
  'Fedora': 3,
  'RedHat': 3,
  'Gentoo': 3,
  'Slackware': 3,
  'SUSE': 3,
  'VectorLinux': 3,
  'Arch': 3,
  'CentOS': 3,
  'Chrome OS': 4,
  'Chromium OS': 4,
  'Android': 5,
  'iOS': 6,
  'Android TV': 7,
  'Tizen': 8,
  'Tizen TV': 8,
  'WebOS': 9,
  'tvOS': 10,
  'VIDAA TV': 11,
  'Roku TV': 12,
  'Fire TV': 13,
}

const mapUaOsToNumber = (os?: string): number => {
  return osMap[os as string] ?? 0
}

const persistedUuid = (key: string, storage: () => Storage): string => {
  let id: string | null = null

  try {
    id = storage().getItem(key)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_ignore) {
    // do nothing
  }

  if (!id) {
    id = uuidv4()
    try {
      storage().setItem(key, id)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_ignore) {
      // do nothing
    }
  }

  return id
}

export const getDeviceId = (): string => {
  return persistedUuid('tria__device_unique_id', () => localStorage)
}

export const getSessionAdId = (): string => {
  return persistedUuid('tria__session_ad_id', () => sessionStorage)
}

export function setUrlMacrosToParams({
  url,
  nonce,
}: SetUrlMacrosToParamsParams): string {
  const now = new Date()

  // https://setplexapps.atlassian.net/wiki/spaces/NORA/pages/2723315713/NF-87.+AVOD.+Macros+support+for+Televen+-+hardcode
  const placeholders: Placeholders = {
    // Platform app name (AV_APPNAME)
    'STPLX_ANM': () => clip(runtime.app.name, 30),
    // App version (AV_APPVERS)
    'STPLX_AVER': () => clip(`v${version}`, 20),
    // Current date in the format yyyy-MM-dd (AVC_DATE)
    'STPLX_DT': () => format(now, DATE_FORMATS.YYYY_MM_DD),
    // Do track flag (defines the permission to data collection).
    'STPLX_DTRACK': () => '1',
    // Do not track flag (defines the prohibition of data collection)
    'STPLX_DNT': () => '0',
    // Current time in format HH-mm-ss (AVC_TIME)
    'STPLX_TM': () => format(now, DATE_FORMATS.HH_MM_SS),
    // Encrypted nonce parameter for Google Ad Manager (AV_UPAL)
    'STPLX_UPAL': () => nonce,
    // Domain, used in URL of page, where the player is running (AV_URL and AV_VIDEOURL)
    'STPLX_DOMAIN': (value, key) =>
      clip(value?.replace(key, window.location.hostname), 100),

    /*
     * New iteration data
     * https://setplexapps.atlassian.net/wiki/spaces/NORA/pages/3335979044/NF-129.+Macros+Replacement+Service
     */

    // Device type (PC/Tablet/Smartphone)
    '[STPLX_DEVICE_TYPE]': () =>
      String(mapUaDeviceTypeToNumber(runtime.device.type)),

    // Device ID
    '[STPLX_DEVICE_ID]': () => getDeviceId(),

    // Operating System
    '[STPLX_OS]': () => String(mapUaOsToNumber(runtime.os.name)),

    // Device Model
    '[STPLX_DEVICE_MODEL]': () => runtime.device.model ?? '',

    // Device Manufacturer
    '[STPLX_DEVICE_MANUFACTURER]': () => runtime.device.vendor ?? '',

    // Application Name
    '[STPLX_APP_NAME]': () => clip(String(runtime.app.appName), 30),

    // App Bundle ID (Package Name) - empty for web
    '[STPLX_BUNDLE_ID]': () => '',

    // App Store URL - empty for web
    '[STPLX_APP_STORE_URL]': () => '',

    // App version
    '[STPLX_APP_VERSION]': () => clip(version, 20),

    // Advertising ID - as type described session lifetime unique id
    '[STPLX_AD_ID]': () => getSessionAdId(),

    // Advertising Type
    '[STPLX_AD_TYPE]': () => 'sessionid',

    // Do Not Track
    '[STPLX_DO_NOT_TRACK]': () => '0',

    // Tracking Allowed
    '[STPLX_TRACK_ALLOWED]': () => '1',

    // System Date
    '[STPLX_SYSTEM_DATE]': () => format(now, DATE_FORMATS.YYYY_MM_DD),

    // System Time
    '[STPLX_SYSTEM_TIME]': () => format(now, DATE_FORMATS['HH:MM:SS']),

    // UUID (Session Identifier) - should be new for each new content
    '[STPLX_SESSION_CONTENT_UUID]': () => uuidv4(),
  }

  const modifiedUrl = new URL(url)
  for (const [name, value] of modifiedUrl.searchParams) {
    let newValue: string | undefined

    for (const [pname, mod] of Object.entries(placeholders)) {
      if (value === pname || value.includes(pname)) {
        newValue = mod(value, pname)
      }
    }

    if (newValue != null) {
      modifiedUrl.searchParams.set(name, newValue)
    }
  }

  return String(modifiedUrl)
}
