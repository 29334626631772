import { Logger } from '@/logger'
import { AggregationTemporalityPreference } from '@opentelemetry/exporter-metrics-otlp-http'
import { resourceFromAttributes } from '@opentelemetry/resources'
import {
  MeterProvider,
  PeriodicExportingMetricReader,
} from '@opentelemetry/sdk-metrics'
import merr from '@setplex/merr'
import { runtime, versions } from '~/app/environment'
import { OTLPMetricExporterOnlyWhenNeeded } from './exporter'
import type { Collector } from './index.h'

export const logger = new Logger('tria/opentelemetry')

/**
 * Initialize OpenTelemetry metrics
 */
export function initTelemetry(collector: Collector): MeterProvider {
  // diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.DEBUG)

  const exporter = new OTLPMetricExporterOnlyWhenNeeded({
    url: collector.url,
    headers: collector.headers || {}, // always set headers, in that case delegate will choose xhr instead of beacon
    // concurrencyLimit: 1,
    temporalityPreference: AggregationTemporalityPreference.DELTA,
  })

  const resource = resourceFromAttributes({
    'service.version': versions.tria,
    'service.name': runtime.app.name, // got from era, e.g. 'tria',
    'service.instance.id': runtime.app.domain,

    // 'device.id': deviceIdentifier || '<unknown>',
    'device.type': runtime.device.libtype || runtime.device.type, // e.g. 'smartphone'
    'device.model.name': runtime.device.model || '<unknown>', // e.g. 's8'
    'device.manufacturer': runtime.os.name || '<unknown>', // e.g. 'Android'
    // 'device.brand': runtime.device.vendor || '<unknown>', // e.g. 'samsung'

    // os.type: // e.g. windows; linux; darwin; ...
    'os.name': runtime.os.name || '<unknown>',
    'os.version': runtime.os.version || '<unknown>',
    // 'device.browserName': runtime.browser.name || '<unknown>',

    // net.host.ip: network_ip
    // net.host.carrier.isp: network_isp
    // net.host.carrier.location: network_location

    // setplex.provider.id: content_provider_id
    // setplex.user.subscriber.id: content_user_subscriber_id
    // setplex.user.account.number: content_user_account_number
    // setplex.user.profile.id: content_user_profile_id
    // setplex.user.profile.name: content_user_profile_name
  })

  // app_releaseVersion: formatReleaseVersion(info.appVersion),
  // app_name: info.appName,
  // app_id: info.appId,
  // device_OsVersion: info.browserVersion,
  // device_model: info.browserName,
  // device_name: info.osName,
  // device_type: 'web',
  // device_id: info.deviceId,
  // network_ip: info.networkIp,
  // network_isp: info.networkIsp,
  // network_location: info.location,
  // content_provider_id: info.contentProviderId,
  // content_user_subscriber_id: info.contentUserSubscriberId,
  // content_user_account_number: info.contentUserAccountNumber,
  // content_user_profile_id: info.contentUserProfileId,
  // content_user_profile_name: info.contentUserProfileName,

  const meterProvider = new MeterProvider({
    resource,
    readers: [
      new PeriodicExportingMetricReader({
        exporter,
        exportIntervalMillis: 10000, // export metrics every 10 seconds
      }),
    ],
  })

  // metrics.setGlobalMeterProvider(meterProvider)

  return meterProvider
}

/**
 * Halt OpenTelemetry metrics
 */
export async function haltTelemetry(
  meterProvider?: MeterProvider | null
): Promise<void> {
  if (meterProvider == null) return

  // meterProvider.forceFlush() // should do this?
  await meterProvider.shutdown()
}

/**
 * Parse and initial validation of collector URL
 */
export function parseCollectorUrl(url?: string | null): Collector | null {
  // check if url is set
  // if url starts with #, it is considered as empty value (like comment)
  if (!url || typeof url !== 'string' || url.startsWith('#')) {
    return null
  }

  // just in case, trim url
  url = url.trim()

  // check if url starts with http or https
  if (!/^https?:\/\//i.test(url)) {
    return {
      url,
      valid: false,
      error: 'Only HTTP or HTTPS URLs are supported',
    }
  }

  // parse url, this will fail on invalid URL
  try {
    const parsed = new URL(url)
    let headers: Record<string, string> | undefined

    // if there are any credentials in the url - cut them and transform to headers
    if (parsed.username || parsed.password) {
      headers = {
        Authorization: `Basic ${btoa(`${parsed.username}:${parsed.password}`)}`,
      }
      parsed.username = ''
      parsed.password = ''
    }

    return {
      url: parsed.toString(),
      headers,
      valid: true,
    }
  } catch (e) {
    return {
      url,
      valid: false,
      error: String(e),
    }
  }
}

/**
 * Validates OpenTelemetry collector URL,
 * by actually sending a minimal valid request to the collector
 */
export async function verifyCollector(collector: Collector): Promise<void> {
  const res = await merr(collector.url, {
    method: 'POST',
    headers: collector.headers,
    json: {
      resourceMetrics: [],
    },
  })

  if (!res.ok) {
    throw new Error('Invalid response from OpenTelemetry collector')
  }
}
