import { logger } from '@/logger'
import Cookies from 'js-cookie'
import { type Env, type Environs } from './index'

const appliable = (env: Env, prop: unknown): prop is Environs =>
  (typeof prop === 'string' &&
    Object.prototype.hasOwnProperty.call(env, prop) &&
    prop.startsWith('TRIA_CLIENT_')) ||
  (logger.error(`unappliable variable "${String(prop)}", ignored`), false)

// value replacer for JSON.stringify call, uses required signature
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const replacer = (_key: string, value: any) =>
  value === Infinity ? 'Infinity' : value

// value reviver for JSON.parse call, uses required signature
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reviver = (_key: string, value: any) =>
  value === 'Infinity' ? Infinity : value

/**
 * Apply "debug" features for env variables.
 * Only appliable for variables, which starts with TRIA_CLIENT_...
 *
 * @param env - object with environment variables
 * @returns proxy object which can be used to modify and save
 *          modified environment variables into cookies
 */
// there is no way ty type this properly ...
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debugProxy<T extends { [k in Environs]: any }>(env: T): T {
  // update env variables from cookies
  for (const prop in env) {
    if (Object.hasOwn(env, prop)) {
      try {
        const value = Cookies.get(prop)
        if (value != null && appliable(env, prop)) {
          env[prop] = JSON.parse(value, reviver)
          logger.warn(`set variable "${prop}" to modified value '${env[prop]}'`)
        }
      } catch (e) {
        logger.error(`failed to override variable "${prop}":`, e)
      }
    }
  }

  // proxy to override env variables values locally,
  // saving them to cookies for later update
  return new Proxy(Object.assign(Object.create(null), env), {
    set(target: Env, prop: Environs, val) {
      if (appliable(target, prop)) {
        try {
          const value = JSON.stringify(val, replacer)
          Cookies.set(prop, value, { expires: 365 })
          logger.warn(`set variable "${prop}" to '${val}', reload page`)
          return true
        } catch (e) {
          logger.error(`failed to set variable "${prop}":`, e)
        }
      }
      return false
    },

    deleteProperty(target: Env, prop: Environs) {
      if (appliable(target, prop)) {
        try {
          Cookies.remove(prop)
          logger.warn(`unset variable "${prop}", reload page`)
          return true
        } catch (e) {
          logger.error(`failed to unset variable "${prop}":`, e)
        }
      }
      return false
    },
  })
}
