import type { API, Session, TOA } from '@setplex/tria-api'
import {
  type AccountDeviceInfo,
  type AnnouncementDto,
  type AnnouncementListDto,
} from '@setplex/wbs-api-types'
import { v4 as uuidv4 } from 'uuid'
import type { HttpClient } from '../http'
import type { AdapterDefaults } from '../index.h'
import type { ApiAnswerAccountDeviceInfo } from '../interfaces/session'

function transformer(dto: AccountDeviceInfo): Session {
  return {
    ...dto,
    lastName: dto.lastName!,
    package: dto.packageName || '-',
    accountNumber: dto.accountNumber || '-',
    subscriberName: dto.subscriberName || '',
    expirationTime: dto.expirationTime,
    toa: dto.toa ?? null,
    // Unique guest id for google pal analytics. Using in loadNonceManagerFx
    // dto.profileId < 0 is shows that this is a guest mode
    guestId: dto.profileId && dto.profileId < 0 ? uuidv4() : null,
  }
}

export function use(
  http: HttpClient,
  session: API['session'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // get session (=account) info
  session.getAccountFx.use(async (opts) => {
    const json = await http.get<ApiAnswerAccountDeviceInfo>(`/account`, {
      searchParams: opts ?? undefined,
    })
    return transformer(json || {})
  })

  // get toa text and buttons
  session.getToaFx.use(async () => {
    const json = await http.get<AnnouncementListDto>(`/account/toa`)
    if (!json || !json.announcements || json.announcements.length === 0) {
      throw new Error('Empty answer in getToaFx')
    }

    const toa: AnnouncementDto | undefined = json.announcements.find(
      (item) => item.type === 'Activation_TOA'
    )
    if (!toa) {
      throw new Error('Not found "Activation_TOA" in getToaFx response')
    }

    return {
      subject: toa.subject,
      message: toa.message,
      buttonType: toa.buttonType,
      tokens: json.tokens,
    } as TOA
  })

  // accept toa
  session.acceptToaFx.use(async () => {
    await http.post(`/account/toa`, {
      json: {
        accepted: true,
      },
    })
  })

  // refresh session
  session.refreshFx.use(async () => {
    return await http.post(`/v3/session/refresh`)
  })
}

/*
  "packageName": "Anna QA",
  "screensaver": 2,
  "menuTheme": "Midnight Grey",
  "subTheme": "Default",
  "themeVideoType": "No_Video",
  "startPage": null,
  "customVideoUrl": null,
  "logoUrl": "https://mkdev.setplex.net/img/networks/114/logo_stb.png",
  "backgroundUrl": "https://mkdev.setplex.net/img/networks/114/bg_stb.png",
  "backgroundColor": "#df7feb",
  "accountNumber": "SP-762-959",
  "subscriberName": "victord",
  "subscriberPin": "1234",
  "expirationTime": "2022-09-23T21:35:13",
  "devices": 7,
  "toa": null,
  "subscriberId": "b769fd1f-2a4a-11eb-ac57-0cc47a3a6bee",
  "deviceIdentifier": "RLmPMj",
  "deviceModel": null,
  "deviceFirmware": null,
  "deviceExternalIP": "80.249.92.147",
  "deviceISP": null,
  "deviceThemes": "Midnight Grey,Rainbow,Premium Sports,MetroPCS,Prismatic,IvoryCoastTV,Milky",
  "location": "Minsk, Belarus, latitude=53.9, longitude=27.5667",
  "offset": "+0300",
  "timezone": "Europe/Minsk",
  "version": "1.42.0#498068cd",
  "apiVersion": "2.0",
  "timeDelta": null,
  "sessionId": "ab61e730-0424-42f4-8f76-51ca61eb60b5",
  "providerId": "998",
  "profileId": 3140,
  "tvEnabled": true,
  "radioEnabled": true,
  "epgEnabled": true,
  "catchupEnabled": true,
  "vodEnabled": true,
  "tvShowEnabled": true,
  "npvrEnabled": false,
  "featuredCarouselsEnabled": true,
  "bannerCarouselsEnabled": true,
  "chatEnabled": true
*/
