import * as era from '!/era'
import { ui } from '!/skin'

// skin
const skin = era.skin('shared/ui/components/language-picker')

interface ILanguagePicker {
  dataTestIdBtn?: string
  dataTestIdList?: string
}

LanguagePicker.displayName = 'LanguagePicker'
export function LanguagePicker(props: ILanguagePicker): JSX.Element | null {
  return (
    <ui.RenderSkin
      from={skin}
      name='languagePicker'
      key='languagePicker'
      {...props}
    />
  )
}
