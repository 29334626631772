import i18n, { type TFunction, type TOptions } from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import { default as HttpBackend } from 'i18next-http-backend'
import MultiloadBackendAdapter from 'i18next-multiload-backend-adapter'
import { initReactI18next } from 'react-i18next'
import { logger } from './lib'

// re-export i18next default instance
export { i18n }

// export translate function
export const t = ((key: string, options?: TOptions) => {
  const translate = i18n.t(key, options)

  if (translate === key && i18n.language !== 'en') {
    return (
      i18n.t(key, {
        ...options,
        lng: 'en',
      }) ?? ''
    )
  }

  return translate ?? ''
}) as TFunction

/**
 * Backend for i18next to load locales from Era
 * @see https://github.com/i18next/i18next-multiload-backend-adapter
 * @see https://github.com/i18next/i18next-http-backend
 */
const httpBackend = new MultiloadBackendAdapter(null, {
  backend: new HttpBackend(null, {
    loadPath(languages, namespaces) {
      const params = new URLSearchParams({
        lang: languages.join('+'),
        ns: namespaces.join('+'),
      })

      return `/api/era/locales?${params}`
    },
  }),
})

/**
 * Chained backend for i18next to use cached locales first, and load locales via HTTP second
 * @see https://github.com/i18next/i18next-chained-backend
 */
const chainedBackend = new ChainedBackend(null, {
  backends: [
    // localStorageBackend,
    httpBackend,
  ],
})

// initialize i18next, and export promise
// TODO: Move some keys (e.g. program-start-end-time) to bundle as fallback
// @see https://www.i18next.com/overview/api
export const initI18n = (defaultLanguage: string) =>
  i18n
    .use(chainedBackend)
    .use(initReactI18next)
    .init(
      {
        returnNull: false,
        returnEmptyString: false,
        partialBundledLanguages: true,
        // debug: true,

        lng: defaultLanguage,
        preload: [defaultLanguage, 'date', 'en'],
        fallbackLng: false,

        ns: ['common', 'errors', 'privacy-policy'],
        defaultNS: 'nora',
        fallbackNS: ['common', 'errors', 'privacy-policy'],
        interpolation: {
          escapeValue: false,
        },

        react: {
          useSuspense: false,
        },
      },
      (err) => {
        if (process.env.NODE_ENV !== 'test') {
          if (err) return logger.error('❌ i18n error:', err)
          logger.debug('🇬🇧 i18n initialized')
        }
      }
    )

// export versions and environment variables for debug and information
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: remove later, I'll leave it now while translations are still in progress
;(window as any).t = t
;(window as any).i18n = i18n
