import { Logger } from '@/logger'
import type { MerrInit } from '@setplex/merr'
import {
  RequestCancelledError,
  RequestFailedError,
  RequestTimeoutError,
  type RequestInput,
} from '@setplex/tria-api'
import type {
  AddressAttributes,
  ConnectionAttributes,
  ErrorAttributes,
  LocationAttributes,
} from './index.h'

export const logger = new Logger('tria/opentelemetry/http')

/**
 * Get an URL from the performance resource timing object
 */
export function getUrlFromPerformanceResourceTiming(
  t: PerformanceResourceTiming
): URL | null {
  try {
    return new URL(t.name)
  } catch (ignore) {
    return null
  }
}

/**
 * Get an URL from the request input OR performance resource timing object
 */
export function getUrlFromRequestInput(input: RequestInput): URL | null {
  let init: MerrInit | undefined
  if (input && typeof input === 'object' && 'input' in input) {
    init = input.init
    input = input.input
  }

  // little hack to preserve method inside URL -> put it into hash
  const method = input instanceof Request ? input.method : init?.method || 'GET'

  if (input instanceof URL) {
    input.hash = method
    return input
  }

  try {
    if (input instanceof Request) {
      const url = new URL(input.url)
      url.hash = method
      return url
    }

    const full = input.startsWith('http://') || input.startsWith('https://')
    const url = new URL(input, full ? undefined : location.href)
    url.hash = method
    return url
  } catch (ignore) {
    return null
  }
}

/**
 * Get a body size from the request input
 */
export function getBodySizeFromRequestInput(input: RequestInput): number {
  let init: MerrInit | undefined
  if (input && typeof input === 'object' && 'input' in input) {
    init = input.init
    input = input.input
  }

  const method = input instanceof Request ? input.method : init?.method || 'GET'

  if (!init || method === 'GET') {
    return 0
  }

  if (init.json) {
    return JSON.stringify(init.json).length
  }

  if (init.body) {
    // TODO: ???
  }

  return 0
}

/**
 * Get all available attributes from a URL
 */
export function attributesFromUrl(
  url: URL
): AddressAttributes & ConnectionAttributes & LocationAttributes {
  const scheme = url.protocol.slice(0, -1) as 'http' | 'https'

  let port = 0
  if (url.port) {
    port = Number(url.port)
  } else if (scheme === 'http') {
    port = 80
  } else if (scheme === 'https') {
    port = 443
  }

  // little hack <- extract method from URL hash
  const method = url.hash.slice(1)

  return {
    'server.address': url.host,
    'url.domain': url.hostname,
    'server.port': port,
    'url.scheme': scheme,
    'http.request.method': method,
    'url.path': url.pathname,
    'url.full': url.href,
  } as const
}

/**
 * Get error type attributes from an error
 */
export function attributesFromError(error?: unknown): ErrorAttributes {
  let errorType: ErrorAttributes['error.type'] = 'fail'
  if (error instanceof RequestFailedError) {
    errorType = 'fail'
  } else if (error instanceof RequestCancelledError) {
    errorType = 'cancel'
  } else if (error instanceof RequestTimeoutError) {
    errorType = 'timeout'
  }

  return {
    'error.type': errorType,
  }
}
