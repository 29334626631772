import type { MeterProvider } from '@opentelemetry/api'
import { createEffect, createEvent, createStore, sample } from 'effector'
import { getUrlFromPerformanceResourceTiming, logger } from './lib'
import { $metrics, createMetricsFx, getMeterFx } from './metrics'
import { timing } from './recorder'

// export `retry` event from `recorder` outside, to be used in `retry_policy`
export { retry } from './recorder'

export const init = createEvent<MeterProvider>()
export const halt = createEvent<void>()

// performance observer to observe http requests
const $performanceObserver = createStore<PerformanceObserver | null>(null)

//
// effects
//

const startObservingFx = createEffect(() => {
  const po = new PerformanceObserver((list) => {
    for (const entry of list.getEntries()) {
      const t = entry as PerformanceResourceTiming
      if (t.initiatorType === 'xmlhttprequest' || t.initiatorType === 'fetch') {
        const url = getUrlFromPerformanceResourceTiming(t)
        if (!url || !url.pathname.startsWith('/wbs')) return
        timing(t) // emit the performance resource timing entry
      }
    }
  })

  po.observe({ type: 'resource', buffered: true })
  return po
})

const haltMetricsFx = createEffect((po: PerformanceObserver) => {
  po.disconnect()
})

//
// init http metrics
//

sample({
  clock: init,
  target: getMeterFx,
})

sample({
  clock: getMeterFx.done,
  fn: () => ['meter created'],
  target: logger.debugFx,
})

sample({
  clock: getMeterFx.fail,
  fn: (fail) => ['cannot create meter', fail],
  target: logger.errorFx,
})

sample({
  clock: getMeterFx.doneData,
  target: createMetricsFx,
})

sample({
  clock: createMetricsFx.done,
  fn: () => ['metrics created'],
  target: logger.debugFx,
})

sample({
  clock: createMetricsFx.fail,
  fn: (fail) => ['cannot create metrics', fail],
  target: logger.errorFx,
})

sample({
  clock: createMetricsFx.doneData,
  target: $metrics,
})

//
// observing http requests
//

sample({
  clock: $metrics,
  filter: Boolean,
  target: startObservingFx,
})

sample({
  clock: startObservingFx.doneData,
  target: $performanceObserver,
})

//
// halt http metrics
//

sample({
  clock: halt,
  fn: () => null,
  target: $metrics,
})

sample({
  clock: halt,
  source: $performanceObserver,
  filter: Boolean,
  target: haltMetricsFx,
})

sample({
  clock: haltMetricsFx,
  fn: () => null,
  target: $performanceObserver,
})

sample({
  clock: haltMetricsFx,
  fn: () => ['metrics halted'],
  target: logger.debugFx,
})
