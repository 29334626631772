import { model as router } from '!/router'
import TrueIDAuth, { type TrueIDAuthConfigType } from '@tdg/trueid-web-sdk'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
  scopeBind,
} from 'effector'
import { not } from 'patronum'
import { env } from '~/shared/config'
import { F, T, aye } from '~/shared/helpers'
import { logger } from '~/shared/logger'
import { type ViewError } from '../index.h'
import { signInFx } from '../model'
import { getTrueIdConfig, getTrueIdLogoutRedirectUrl } from './helpers'

export const init = createEvent()
export const $ready = createStore(false)
export const $enabled = createStore(false)

export const $trueIdConfig = createStore(
  env.TRIA_CLIENT_FORCE_REGULAR_SIGNIN
    ? null // bypass TrueID sign-in for debug and testing purposes
    : getTrueIdConfig()
)

export const $trueIdError = createStore<ViewError>(null)

const $trueIdAuthClient = createStore<TrueIDAuth | null>(null)

export const initTrueIdAuthClientFx = createEffect(
  (config: TrueIDAuthConfigType) => {
    if (config) return new TrueIDAuth(config)
    throw new Error('TrueID config is not provided')
  }
)

export const trueIdGetStatusFx = attach({
  source: $trueIdAuthClient,
  effect(trueIdAuthClient) {
    if (trueIdAuthClient) return trueIdAuthClient.getStatus()
    throw new Error('TrueID client is not initialized')
  },
})

export const trueIdSignInFx = attach({
  source: $trueIdAuthClient,
  effect: (trueIdAuthClient) => {
    if (trueIdAuthClient == null) {
      throw new Error('TrueID client is not initialized')
    }

    // @ts-ignore: TS2769 because don't have third param for describing api error insisde scopeBind types
    const signIn = scopeBind(signInFx, { safe: true })
    const getStatus = scopeBind(trueIdGetStatusFx, { safe: true })

    trueIdAuthClient.login(async (status) => {
      try {
        await signIn({ foreignPlatformAccessToken: status.access_token })
        await getStatus()
      } catch (e) {
        logger.log('TrueId auth error', e)
        // Possible errors are handled by trueId auth form
        // Empty catch block is needed here to mark possible exceptions as handled
      }
    })
  },
})

export const trueIdAutoLoginFx = attach({
  source: $trueIdAuthClient,
  effect: async (trueIdAuthClient) => {
    if (trueIdAuthClient == null) {
      throw new Error('TrueID client is not initialized')
    }

    const status = await trueIdGetStatusFx()
    if (status.access_token) {
      await signInFx({ foreignPlatformAccessToken: status.access_token })
    }
  },
})

export const trueIdAutoLoginLostAuthFx = attach({
  effect: trueIdAutoLoginFx,
})

export const trueIdLogoutFx = attach({
  source: {
    location: router.$location,
    trueIdAuthClient: $trueIdAuthClient,
  },
  effect: async ({ location, trueIdAuthClient }) => {
    if (trueIdAuthClient == null) {
      throw new Error('TrueID client is not initialized')
    }

    const redirectUrl = getTrueIdLogoutRedirectUrl(
      window.location.origin,
      location
    )

    await trueIdAuthClient.logout(redirectUrl)
  },
})

//
// init trueId
//

sample({
  clock: init,
  source: $trueIdConfig,
  filter: (config): config is TrueIDAuthConfigType => config != null,
  target: initTrueIdAuthClientFx,
})

sample({
  clock: init,
  source: $trueIdConfig,
  filter: (config) => config == null,
  fn: T,
  target: $ready,
})

sample({
  clock: initTrueIdAuthClientFx.finally,
  fn: T,
  target: $ready,
})

sample({
  clock: initTrueIdAuthClientFx.doneData,
  target: $trueIdAuthClient,
})

sample({
  clock: $trueIdAuthClient,
  fn: Boolean,
  target: $enabled,
})

sample({
  clock: initTrueIdAuthClientFx.failData,
  fn: (error) => ['failed to init trueId', error],
  target: logger.errorFx,
})

//
// trueId session
//

export const trueIdAutoLogin = createEvent()

export const $isTrueIdAuthed = createStore<boolean | null>(null)
  .on(trueIdGetStatusFx.doneData, (_, status) => aye(status.access_token))
  .on(trueIdGetStatusFx.fail, F)

sample({
  clock: trueIdAutoLoginLostAuthFx.fail,
  target: trueIdLogoutFx,
})

sample({
  clock: trueIdAutoLogin,
  filter: not(trueIdAutoLoginLostAuthFx.pending),
  target: trueIdAutoLoginLostAuthFx,
})
