import { model as sessionModel } from '$/session'
import { aye, exists, nay } from '@/helpers'
import { createEffect, createEvent, createStore, sample } from 'effector'
import { Workbox } from 'workbox-window'
import { model as logoFeatureModel } from '~/features/logo'
import { installNotificationsModel } from '~/features/notifications/install'
import { model as guestModel } from '~/features/subscriber/guest'
import { logger } from '~/shared/logger'

export const init = createEvent()

// init workbox to work with service worker
export const createWorkboxFx = createEffect(() => {
  if ('serviceWorker' in navigator) {
    return process.env.NODE_ENV === 'production'
      ? new Workbox('/sw.js?' + __VCS_COMMITHASH__, { type: 'classic' })
      : new Workbox('/dev-sw.js?dev-sw', { type: 'module' })
  }
  throw new Error('Service workers are not supported')
})

// register service worker
export const registerFx = createEffect((wb: Workbox) => wb.register())

// workbox instance
export const $workbox = createStore<Workbox | null>(null) //
  .on(createWorkboxFx.doneData, (_, wb) => wb)

// service worker registration
export const $registration = createStore<ServiceWorkerRegistration | null>(null) //
  .on(registerFx.doneData, (_, registration) => registration)

sample({
  clock: init,
  target: createWorkboxFx,
})

sample({
  clock: createWorkboxFx.doneData,
  target: registerFx,
})

sample({
  clock: registerFx.doneData,
  fn: () => ['sw is registered'],
  target: logger.debugFx,
})

sample({
  clock: registerFx.failData,
  fn: (err) => ['sw registration failed', err],
  target: logger.errorFx,
})

sample({
  clock: [
    registerFx.done,
    guestModel.$guest,
    sessionModel.$session,
    sessionModel.$authenticated,
  ],
  source: {
    workbox: $workbox,
    serviceWorkerRegistration: $registration,
    authenticated: sessionModel.$authenticated,
    isGuest: guestModel.$guest,
    isExpired: sessionModel.$expired,
  },
  filter: ({
    workbox,
    serviceWorkerRegistration,
    authenticated,
    isGuest,
    isExpired,
  }) =>
    exists(workbox) &&
    exists(serviceWorkerRegistration) &&
    authenticated &&
    !isExpired &&
    // isGuest may be null. We need to make sure isGuest is false.
    isGuest === false,
  fn: ({ workbox, serviceWorkerRegistration }) => ({
    workbox: workbox!,
    serviceWorkerRegistration: serviceWorkerRegistration!,
  }),
  target: installNotificationsModel.setup,
})

sample({
  clock: [
    $workbox,
    installNotificationsModel.$pushNotificationsEnabled,
    guestModel.$guest,
  ],
  source: {
    authenticated: sessionModel.$authenticated,
    workbox: $workbox,
    isGuest: guestModel.$guest,
    pushNotificationsEnabled:
      installNotificationsModel.$pushNotificationsEnabled,
    messaging: installNotificationsModel.$messaging,
  },
  filter: ({
    workbox,
    messaging,
    authenticated,
    pushNotificationsEnabled,
    isGuest,
  }) =>
    exists(messaging) &&
    exists(workbox) &&
    (nay(authenticated) || nay(pushNotificationsEnabled) || aye(isGuest)),
  fn: ({ workbox, messaging }) => ({
    workbox: workbox!,
    messaging: messaging!,
  }),
  target: installNotificationsModel.unregisterPushNotificationFx,
})

sample({
  clock: logoFeatureModel.loadImageFx.doneData,
  source: logoFeatureModel.$logoUrl,
  target: installNotificationsModel.setLogoUrl,
})
