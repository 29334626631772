import { register } from '!/repository'
import { model as router } from '!/router'

import type { IRemoveProfile } from '$/profiles-popup/remove'
import { removeProfilePopupModel } from '$/profiles-popup/remove'

import type { ICreateProfilePopup } from '$/profiles-popup/create'
import { createProfilePopupModel } from '$/profiles-popup/create'

import { aye, nay } from '@/helpers'
import { createEvent, sample } from 'effector'
import { profilesModel } from '~/entities/profiles'
import { model as sessionModel } from '~/entities/session'
import { model as subscriber } from '~/entities/subscriber'
import { portalRedirectModel } from '~/features/portal/redirect'
import { profilesFeaturesModel } from '~/features/profiles'
import { model as guestModel } from '~/features/subscriber/guest'
import { $navbarDropDownTitles, $navbarTabTitles } from './translations'

const $signInHref = router.$href.map(
  (href) => '/sign-in' + (href && href !== '/' ? '/~' + href : '')
)

const chooseActiveProfile = createEvent<{ id: number }>()

const openRemoveProfilePopup = createEvent<IRemoveProfile>()

const removeProfile = createEvent<Pick<IRemoveProfile, 'id'>>()

const openCreateProfileModal = createEvent<ICreateProfilePopup>()

//update list of profiles after reload page
sample({
  clock: sessionModel.$session,
  source: {
    profiles: profilesModel.$optimisticProfiles,
    toaAccepted: sessionModel.$toaAccepted,
    isGuest: guestModel.$guest,
    activeProfile: profilesModel.$activeProfile,
  },
  filter: ({ profiles, toaAccepted, isGuest, activeProfile }, session) =>
    nay(profiles.length) &&
    aye(session) &&
    aye(toaAccepted) &&
    nay(isGuest) &&
    aye(activeProfile),
  target: profilesModel.getProfilesFx,
})

sample({
  clock: chooseActiveProfile,
  source: profilesModel.$activeProfile,
  filter: (activeProfile, { id }) => activeProfile?.id !== id,
  fn: (_, { id }) => id,
  target: profilesModel.updateProfileActiveStateFx,
})

sample({
  clock: openRemoveProfilePopup,
  source: profilesModel.$activeProfile,
  filter: (activeProfile, { id }) => id != null && activeProfile?.id !== id,
  fn: (_, clockData) => clockData,
  target: removeProfilePopupModel.openProfilesRemovePopup,
})

sample({
  clock: removeProfile,
  source: profilesModel.$activeProfile,
  filter: (activeProfile, { id }) => id != null && activeProfile?.id !== id,
  fn: (_, { id }) => id!,
  target: profilesModel.removeProfileFx,
})

sample({
  clock: openCreateProfileModal,
  target: createProfilePopupModel.openProfilesCreatePopup,
})

register($signInHref, '$navbar/signInHref')
register(profilesModel.$activeProfile, '$navbar/activeProfile')
register(profilesModel.$optimisticProfiles, '$navbar/profiles')
register(openCreateProfileModal, 'openCreateProfileModal')
register(openRemoveProfilePopup, 'openRemoveProfilePopup')
register(removeProfile, 'removeProfile')
register(chooseActiveProfile, 'chooseActiveProfile')
register(removeProfile, 'removeProfile')
register($navbarTabTitles, '$navbarTabTitles')
register($navbarDropDownTitles, '$navbarDropDownTitles')
register(subscriber.$subscriber, '$subscriberInfo')
register(
  portalRedirectModel.openAccountSettingsModal,
  'openAccountSettingsModal'
)
register(
  portalRedirectModel.$remoteSubscriberAccountSettingsURL,
  '$remoteSubscriberAccountSettingsURL'
)
register(profilesFeaturesModel.$isMaxNumberOfProfiles, '$isMaxNumberOfProfiles')
register(profilesFeaturesModel.$profileAddTitle, '$profile/addProfileTitle')
