import { type Location } from '!/router'
import { Logger } from '@/logger'
import { UNKNOWN_PREVIOUS_LOCATION } from './index.h'

export const logger = new Logger('tria/sentry')

/**
 * Composes href from location
 */
const href = (location?: Location | null): string =>
  location
    ? `${location.pathname}${location.search}${location.hash}`
    : UNKNOWN_PREVIOUS_LOCATION

/**
 * Composes breadcrumbs from previous locations
 */
export const toBreadcrumbs = (locations: (Location | null)[] | null) =>
  locations ? locations.map(href).reverse() : []
