import { DataMessageType, model as sse, type DataEvent } from '!/sse'
import { combine, createEvent, createStore, sample } from 'effector'
import { model as session } from '~/entities/session'
import { type Purchased } from './index.h'
import { adjust, pushTo } from './lib'

const addPurchase = createEvent<DataEvent['data']>()
const $purchased = createStore<Purchased>({
  channel: [],
  channelSet: [],
  episode: [],
  liveEvent: [],
  partnerProduct: [],
  season: [],
  subscription: [],
  tvShow: [],
  tvShowSet: [],
  vod: [],
  vodSet: [],
})
  .reset(session.$session)
  .on(addPurchase, (purchased, { type, itemId }) => {
    const push = pushTo(purchased)
    switch (type) {
      case DataMessageType.CHANNEL_PURCHASE_EVENT:
        return push('channel', itemId)
      case DataMessageType.CHANNEL_SET_PURCHASE_EVENT:
        return push('channelSet', itemId)
      case DataMessageType.EPISODE_PURCHASE_EVENT:
        return push('episode', itemId)
      case DataMessageType.LIVE_EVENT_PURCHASE_EVENT:
        return push('liveEvent', itemId)
      case DataMessageType.PARTNER_PRODUCT_PURCHASE_EVENT:
        return push('partnerProduct', itemId)
      case DataMessageType.SEASON_PURCHASE_EVENT:
        return push('season', itemId)
      case DataMessageType.SUBSCRIPTION_PURCHASE_EVENT:
        return push('subscription', itemId)
      case DataMessageType.TV_SHOW_PURCHASE_EVENT:
        return push('tvShow', itemId)
      case DataMessageType.TV_SHOW_SET_PURCHASE_EVENT:
        return push('tvShowSet', itemId)
      case DataMessageType.VOD_PURCHASE_EVENT:
        return push('vod', itemId)
      case DataMessageType.VOD_SET_PURCHASE_EVENT:
        return push('vodSet', itemId)
      default:
        return purchased
    }
  })

sample({
  clock: sse.dataMessage,
  target: addPurchase,
})

export const $channelIds = $purchased.map((p) => p.channel)
export const $channelSetIds = $purchased.map((p) => p.channelSet)
export const $episodeIds = $purchased.map((p) => p.episode)
export const $liveEventIds = $purchased.map((p) => p.liveEvent)
export const $partnerProductIds = $purchased.map((p) => p.partnerProduct)
export const $seasonIds = $purchased.map((p) => p.season)
export const $subscriptionIds = $purchased.map((p) => p.subscription)
export const $tvShowIds = $purchased.map((p) => p.tvShow)
export const $tvShowSetIds = $purchased.map((p) => p.tvShowSet)
export const $vodIds = $purchased.map((p) => p.vod)
export const $vodSetIds = $purchased.map((p) => p.vodSet)

const $s = session.$session
export const $channel = combine($purchased, $s, adjust('channel', 'channelSet'))
export const $channelSet = combine($purchased, $s, adjust('channelSet'))
export const $episode = combine($purchased, $s, adjust('episode', 'tvShowSet'))
export const $liveEvent = combine($purchased, $s, adjust('liveEvent'))
export const $partnerProduct = combine($purchased, $s, adjust('partnerProduct'))
export const $season = combine($purchased, $s, adjust('season', 'tvShowSet'))
export const $subscription = combine($purchased, $s, adjust('subscription'))
export const $tvShow = combine($purchased, $s, adjust('tvShow', 'tvShowSet'))
export const $tvShowSet = combine($purchased, $s, adjust('tvShowSet'))
export const $vod = combine($purchased, $s, adjust('vod', 'vodSet'))
export const $vodSet = combine($purchased, $s, adjust('vodSet'))

// generic adjust for any content type
export const $adjust = combine(
  $purchased,
  $s,
  (purchased, session) => (type: keyof Purchased, bundle?: keyof Purchased) =>
    adjust(type, bundle)(purchased, session)
)
