import { Logger } from '~/shared/logger'
import {
  EVENT,
  SystemEventType,
  type AnyEvent,
  type DataEvent,
  type SystemEvent,
  type SystemEventClosePayload,
  type SystemEventConnectedPayload,
  type SystemEventPayload,
  type SystemEventPingPayload,
  type SystemEventReconnectPayload,
} from './index.h'

export const logger = new Logger('tria/sse')

// TODO: add contract validation library - https://setplexapps.atlassian.net/browse/FP-2487
export function isSystemEvent(x: AnyEvent): x is SystemEvent {
  return (
    x.event === EVENT.system &&
    x.data != null &&
    typeof x.data === 'object' &&
    'type' in x.data &&
    typeof x.data.type === 'string'
  )
}

// TODO: add contract validation library - https://setplexapps.atlassian.net/browse/FP-2487
export function isDataEvent(x: AnyEvent): x is DataEvent {
  return (
    x.event === EVENT.data &&
    x.data != null &&
    typeof x.data === 'object' &&
    'type' in x.data &&
    typeof x.data.type === 'string' &&
    'itemId' in x.data &&
    typeof x.data.itemId === 'number'
  )
}

export function isSystemEventClose(
  x: SystemEventPayload
): x is SystemEventClosePayload {
  return x.type === SystemEventType.CLOSE
}

export function isSystemEventConnected(
  x: SystemEventPayload
): x is SystemEventConnectedPayload {
  return x.type === SystemEventType.CONNECTED
}

export function isSystemEventPing(
  x: SystemEventPayload
): x is SystemEventPingPayload {
  return x.type === SystemEventType.PING
}

export function isSystemEventReconnect(
  x: SystemEventPayload
): x is SystemEventReconnectPayload {
  return x.type === SystemEventType.RECONNECT
}
