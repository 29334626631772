import {
  PriceSettingsAvailabilityPeriodTimeUnit,
  PriceSettingsPaymentMethod,
  PriceSettingsType,
  PurchaseInfoContentPurchaseType,
  type FormattedPriceSettings,
  type PurchaseInfo,
  type Session,
} from '@setplex/tria-api'
import {
  type AdjustPurchaseableFn,
  type Purchaseable,
  type Purchased,
} from './index.h'

export const pushTo =
  (purchased: Purchased) =>
  (key: keyof Purchased, value: number): Purchased => {
    const list = purchased[key]
    if (list.includes(value)) return purchased
    purchased[key] = [...list, value]
    return { ...purchased }
  }

/**
 * Create `purchaseInfo` for item from its `priceSettings`
 */
const infoFromPriceSetting = (
  setting: FormattedPriceSettings,
  session: Session | null
): PurchaseInfo => {
  if (setting.type === PriceSettingsType.Purchase) {
    return {
      contentPurchaseType: PurchaseInfoContentPurchaseType.Bought,
    }
  }

  const unit = setting.availabilityPeriodTimeUnit
  const length = setting.availabilityPeriodLength
  if (!unit || !length) {
    return {
      contentPurchaseType: PurchaseInfoContentPurchaseType.Rented,
    }
  }

  const until = new Date()
  if (unit === PriceSettingsAvailabilityPeriodTimeUnit.Day) {
    until.setDate(until.getDate() + length)
  } else if (unit === PriceSettingsAvailabilityPeriodTimeUnit.Month) {
    until.setMonth(until.getMonth() + length)
  } else {
    until.setFullYear(until.getFullYear() + length)
  }

  // adjust `rentedUntil` to current subscription expiration
  if (session && session.expirationTime) {
    const expiration = new Date(session.expirationTime)
    if (until > expiration) {
      until.setTime(expiration.getTime())
    }
  }

  return {
    contentPurchaseType: PurchaseInfoContentPurchaseType.Rented,
    rentedUntil: until.toISOString(),
  }
}

const isRented = (purchaseInfo: PurchaseInfo) =>
  purchaseInfo.contentPurchaseType === PurchaseInfoContentPurchaseType.Rented

/**
 * Adjust purchaseable item with purchase info
 *
 * `purchaseInfo` is calculated from `priceSettings` of the item,
 * this allows to eliminate extra API calls to get purchase info,
 * but there are could be issues, when one item has multiple price settings,
 * it is impossible to know, which one to use... I use first one
 */
export const adjust =
  (type: keyof Purchased, bundle?: keyof Purchased) =>
  (purchased: Purchased, session: Session | null): AdjustPurchaseableFn =>
  <T extends Purchaseable | undefined>(item: T): T => {
    if (item == null || item.id == null) return item
    if (item.purchaseInfo != null) return item

    const priceSettings = ([] as FormattedPriceSettings[]).concat(
      item.priceSettings || [],
      item.tvShowPriceSettings || [],
      item.seasonPriceSettings || [],
      item.episodePriceSettings || []
    )

    if (!priceSettings.length) return item

    // check if the item is already purchased as per set
    if (bundle && purchased[bundle].length) {
      const purchasedBundles = purchased[bundle]
      for (const setting of priceSettings) {
        const bundle = setting.bundle
        if (
          setting.paymentMethod === PriceSettingsPaymentMethod.PerSet &&
          bundle &&
          bundle.id &&
          purchasedBundles.includes(bundle.id)
        ) {
          const purchaseInfo = infoFromPriceSetting(setting, session)
          return {
            ...item,
            rented: isRented(purchaseInfo),
            purchaseInfo,
          }
        }
      }
    }

    // check if the item is already purchased as per item
    const list = purchased[type]
    if (list.length && list.includes(item.id)) {
      for (const setting of priceSettings) {
        if (setting.paymentMethod === PriceSettingsPaymentMethod.PerItem) {
          const purchaseInfo = infoFromPriceSetting(setting, session)
          return {
            ...item,
            rented: isRented(purchaseInfo),
            purchaseInfo,
          }
        }
      }

      // don't know how item was bought...
      // get first price setting and use it to create purchase info
      const setting = priceSettings[0]
      const purchaseInfo = infoFromPriceSetting(setting, session)
      return {
        ...item,
        rented: isRented(purchaseInfo),
        purchaseInfo,
      }
    }

    // item is not purchased
    return item
  }
