import { api } from '@setplex/tria-api'
import { attach, createEvent, sample, type EventPayload } from 'effector'
import { ONE_SECOND_MS } from '~/shared/constants/time'
import {
  attributesFromError,
  attributesFromUrl,
  getBodySizeFromRequestInput,
  getUrlFromPerformanceResourceTiming,
  getUrlFromRequestInput,
} from './lib'
import { $metrics } from './metrics'

//
// record metrics effects
//

/**
 * Record metrics for a performance resource timing entry,
 * triggered by performance observer
 */
const recordTimingFx = attach({
  source: $metrics,
  effect(metrics, t: PerformanceResourceTiming) {
    if (!metrics) return

    const url = getUrlFromPerformanceResourceTiming(t)
    if (!url) return

    const requestAttrs = attributesFromUrl(url)
    const responseAttrs = {
      ...requestAttrs,
      'http.response.status_code': t.responseStatus,
    }
    const dnsAttrs = {
      'server.address': requestAttrs['server.address'],
      'url.domain': requestAttrs['url.domain'],
    }
    const connectionAttrs = {
      ...dnsAttrs,
      'server.port': requestAttrs['server.port'],
      'url.scheme': requestAttrs['url.scheme'],
    }

    metrics.http_client_request_duration.record(
      t.duration / ONE_SECOND_MS,
      responseAttrs
    )

    metrics.http_client_dns_duration.record(
      t.domainLookupEnd - t.domainLookupStart,
      dnsAttrs
    )

    metrics.http_client_connection_duration.record(
      t.connectEnd - t.connectStart,
      connectionAttrs
    )

    metrics.http_client_request_send_duration.record(
      t.responseStart - t.requestStart,
      requestAttrs
    )

    metrics.http_client_response_receive_duration.record(
      t.responseEnd - t.responseStart,
      responseAttrs
    )

    metrics.http_client_response_body_size.record(
      t.decodedBodySize,
      responseAttrs
    )
  },
})

/**
 * Record metrics for a HTTP request,
 * triggered by HTTP client request event
 */
const recordRequestFx = attach({
  source: $metrics,
  effect(metrics, { input }: EventPayload<typeof api.events.http.request>) {
    if (!metrics) return

    const url = getUrlFromRequestInput(input)
    if (!url) return

    const requestAttrs = attributesFromUrl(url)

    metrics.http_client_requests_count.add(1, requestAttrs)

    metrics.http_client_request_body_size.record(
      getBodySizeFromRequestInput(input),
      requestAttrs
    )
  },
})

/**
 * Record metrics for a HTTP response,
 * triggered by HTTP client response event
 */
const recordResponseFx = attach({
  source: $metrics,
  effect(
    metrics,
    { input, response }: EventPayload<typeof api.events.http.response>
  ) {
    if (!metrics) return

    const url = getUrlFromRequestInput(input)
    if (!url) return

    const requestAttrs = attributesFromUrl(url)
    const responseAttrs = {
      ...requestAttrs,
      'http.response.status_code': response.status,
    }

    metrics.http_client_response_count.add(1, responseAttrs)
  },
})

/**
 * Record metrics for a HTTP error,
 * triggered by HTTP client error event
 */
const recordErrorFx = attach({
  source: $metrics,
  effect(
    metrics,
    { input, error }: EventPayload<typeof api.events.http.error>
  ) {
    if (!metrics) return

    const url = getUrlFromRequestInput(input)
    if (!url) return

    const requestAttrs = attributesFromUrl(url)
    const errorAttrs = attributesFromError(error)
    const failAttrs = {
      ...requestAttrs,
      ...errorAttrs,
    }

    metrics.http_client_requests_fails_count.add(1, failAttrs)
  },
})

/**
 * Record metrics for a HTTP retry,
 * triggered by retry policy
 */
const recordRetryFx = attach({
  source: $metrics,
  effect(
    metrics,
    { request, response, error, attempt }: EventPayload<typeof retry>
  ) {
    if (!metrics) return
    if (!request) return

    const url = getUrlFromRequestInput(request)
    if (!url) return

    const requestAttrs = attributesFromUrl(url)
    const reRequestAttrs = {
      ...requestAttrs,

      // retry policy emits this event before actual retry request send,
      // and `attempt` shows how many times request was sent prior to this attempt,
      // so we need to add 1 to get actual resend count in metrics
      'http.request.resend_count': attempt + 1,
    }

    metrics.http_client_requests_count.add(1, reRequestAttrs)

    // record response metrics if response is present
    if (response) {
      const responseAttrs = {
        ...requestAttrs,
        'http.response.status_code': response.status,
      }

      metrics.http_client_response_count.add(1, responseAttrs)
    }

    // record error metrics if error is present
    if (error) {
      const errorAttrs = attributesFromError(error)
      const failAttrs = {
        ...requestAttrs,
        ...errorAttrs,
      }

      metrics.http_client_requests_fails_count.add(1, failAttrs)
    }
  },
})

//
// setup recorders
//

const $enabled = $metrics.map(Boolean)

// event, triggered by performance observer
// https://mdn.github.io/shared-assets/images/diagrams/api/performance/timestamp-diagram.svg
export const timing = createEvent<PerformanceResourceTiming>()

// event, triggered by retry policy
export const retry = createEvent<{
  request?: Request
  response?: Response
  error?: Error
  attempt: number
}>()

sample({
  clock: timing,
  filter: $enabled,
  target: recordTimingFx,
})

sample({
  clock: api.events.http.request,
  filter: $enabled,
  target: recordRequestFx,
})

sample({
  clock: api.events.http.response,
  filter: $enabled,
  target: recordResponseFx,
})

sample({
  clock: api.events.http.error,
  filter: $enabled,
  target: recordErrorFx,
})

sample({
  clock: retry,
  filter: $enabled,
  target: recordRetryFx,
})
