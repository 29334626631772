import { type IUrlRequestParams } from '$/player/tv/index.h'
import { generateEmptyProgramForNow, getCurrentProgram } from '$/player/tv/lib'
import type {
  EpgInfoDto,
  TimeRange,
  TvChannel,
  TvEpgApiDto,
} from '@setplex/tria-api'
import { api } from '@setplex/tria-api'
import type { ApiEffect } from '@setplex/tria-api/src/index.h'
import {
  attach,
  combine,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { ssePurchasesModel } from '~/features/payment/sse-purchases'
import { type PlayerAllTvQueries } from '~/shared/interfaces'

export const initPlayerPageTvPlaying = createEvent<PlayerAllTvQueries>()

sample({
  clock: initPlayerPageTvPlaying,
  fn: ({ categoryId, ...initPlayerPageTvParams }) => ({
    ...initPlayerPageTvParams,
    categoryId: categoryId || 0,
  }),
  target: api.tv.generateTvPlaylist,
})

export const setCurrentChannel = createEvent<TvChannel>()
export const updatePlayingChannelEPGInfo = createEvent()
export const $currentChannel = restore(setCurrentChannel, null)

export const $currentChannelSSE = combine(
  $currentChannel,
  ssePurchasesModel.$channel,
  (channel, adjust) => (channel ? adjust(channel) : channel)
)

export const getUrlAndEpgWithRewind = createEvent<{ period: TimeRange }>()

export const getEpgInfoForTvChannelFx: ApiEffect<
  { period: TimeRange },
  { epg: TvEpgApiDto; period: TimeRange }
> = attach({
  effect: api.tv.getEpgInformationFx,
  source: { channel: $currentChannelSSE },
  mapParams: ({ period }, { channel }) => ({
    period,
    epgIds: [channel?.epgId || ''],
  }),
})

sample({
  clock: getUrlAndEpgWithRewind,
  target: getEpgInfoForTvChannelFx,
})

export const updateEpgInfoForTvChannelFx: ApiEffect<
  { period: TimeRange },
  { epg: TvEpgApiDto; period: TimeRange }
> = attach({
  effect: api.tv.getEpgInformationFx,
  source: { channel: $currentChannelSSE },
  mapParams: ({ period }, { channel }) => ({
    period,
    epgIds: [channel?.epgId || ''],
  }),
})

export const $program = createStore<EpgInfoDto | null>(null)
  // real program
  .on(getEpgInfoForTvChannelFx.doneData, (_, payload) =>
    getCurrentProgram(payload)
  )
  // generated program to show some rewindeable data
  .on(getEpgInfoForTvChannelFx.fail, (_) => generateEmptyProgramForNow())
  .reset(getEpgInfoForTvChannelFx)

export const $updatedProgram = createStore<EpgInfoDto | null>(null)
  .on(updateEpgInfoForTvChannelFx.doneData, (_, payload) =>
    getCurrentProgram(payload)
  )
  .on(updateEpgInfoForTvChannelFx.fail, (_) => generateEmptyProgramForNow())
  .reset(updateEpgInfoForTvChannelFx)

const epgDataForNewChannelIsReady = createEvent<IUrlRequestParams>()

const epgUpdateDataForChannelIsReady = createEvent<EpgInfoDto>()

sample({
  clock: $program,
  source: $currentChannelSSE,
  filter: (channel, program) => Boolean(program) && Boolean(channel),
  fn: (channel, program) => ({ channel, program }) as IUrlRequestParams,
  target: epgDataForNewChannelIsReady,
})

sample({
  clock: $updatedProgram,
  source: $currentChannelSSE,
  filter: (channel, program) => Boolean(program) && Boolean(channel),
  fn: (_, program) => program as EpgInfoDto,
  target: epgUpdateDataForChannelIsReady,
})

export const rewind = createEvent<boolean>()

export const updateEpgDataForCurrentUrl = createEvent<EpgInfoDto>()

sample({
  clock: epgUpdateDataForChannelIsReady,
  target: updateEpgDataForCurrentUrl,
})

export const getPlayerTvUrlSyncedInfo = createEvent<IUrlRequestParams>()

sample({
  clock: epgDataForNewChannelIsReady,
  target: getPlayerTvUrlSyncedInfo,
})
