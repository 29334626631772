import { ONE_MINUTE_MS } from '~/shared/constants/time'

export const SSE_PING_TIMEOUT = 5 * ONE_MINUTE_MS // 5 minutes

//
// event types
//

export enum EVENT {
  system = 'system',
  data = 'data',
}

export const SUBSCRIBE: EVENT[] = [EVENT.system, EVENT.data]

export type AnyEvent = {
  event: EVENT
  data: unknown
  id?: string
}

// `system` control events from backend
export type SystemEvent = AnyEvent & {
  event: EVENT.system
  data: SystemEventPayload
}

// `data` logic events
export type DataEvent = AnyEvent & {
  event: EVENT.data
  data: DataEventPayload
}

//
// `system` control events from backend
//

export enum SystemEventType {
  CLOSE = 'CLOSE',
  CONNECTED = 'CONNECTED',
  PING = 'PING',
  RECONNECT = 'RECONNECT',
}

export type SystemEventPayload = {
  type: SystemEventType
}

export type SystemEventClosePayload = SystemEventPayload & {
  type: SystemEventType.CLOSE
}

export type SystemEventConnectedPayload = SystemEventPayload & {
  type: SystemEventType.CONNECTED
}

export type SystemEventPingPayload = SystemEventPayload & {
  type: SystemEventType.PING
}

export type SystemEventReconnectPayload = SystemEventPayload & {
  type: SystemEventType.RECONNECT
  retry: number // reconnection timeout in milliseconds
}

//
// `data` logic events
//

export enum DataMessageType {
  CHANNEL_PURCHASE_EVENT = 'CHANNEL_PURCHASE_EVENT',
  CHANNEL_SET_PURCHASE_EVENT = 'CHANNEL_SET_PURCHASE_EVENT',
  EPISODE_PURCHASE_EVENT = 'EPISODE_PURCHASE_EVENT',
  LIVE_EVENT_PURCHASE_EVENT = 'LIVE_EVENT_PURCHASE_EVENT',
  PARTNER_PRODUCT_PURCHASE_EVENT = 'PARTNER_PRODUCT_PURCHASE_EVENT',
  SEASON_PURCHASE_EVENT = 'SEASON_PURCHASE_EVENT',
  SUBSCRIPTION_PURCHASE_EVENT = 'SUBSCRIPTION_PURCHASE_EVENT',
  TV_SHOW_PURCHASE_EVENT = 'TV_SHOW_PURCHASE_EVENT',
  TV_SHOW_SET_PURCHASE_EVENT = 'TV_SHOW_SET_PURCHASE_EVENT',
  VOD_PURCHASE_EVENT = 'VOD_PURCHASE_EVENT',
  VOD_SET_PURCHASE_EVENT = 'VOD_SET_PURCHASE_EVENT',
}

export type DataEventPayload = {
  type: DataMessageType
  itemId: number // id of the content from type
  metadata?: {
    tvShowId?: number // for types "SEASON_PURCHASE_EVENT", "EPISODE_PURCHASE_EVENT"
    seasonId?: number // for type "EPISODE_PURCHASE_EVENT"
  }
}
