import { ONE_HOUR_MS, ONE_MINUTE_MS } from '@/constants/time'
import { debugProxy } from './debug'

export type Env = typeof env
export type Environs = keyof Env

// env variables from non-cacheable index.html
const envs: Record<string, string> = '__ENV__' in window ? window.__ENV__ : {}

/**
 * System environment variables
 * They works application-wide, for any provider
 *
 * If variable starts with TRIA_CLIENT_..., this means it can be changed
 * in browser via applied debug functionality (see below)
 */
export const env = {
  // Application environment, e.g. "us", "us-k8s", "by-dev", "qa" etc
  TRIA_ENVIRONMENT: envs.TRIA_ENVIRONMENT,

  // This variable overrides provider, used by Era service to serve customization,
  // e.g. 'foqustvlive.com'
  TRIA_CLIENT_SOURCE_ORIGIN: envs.TRIA_CLIENT_SOURCE_ORIGIN,

  // This variable is used by Ky HTTP client, and allows to modify wait timeout,
  // for testing purposes. By default = 10000, meaning 10 seconds.
  // Can be set to 'Infinity' to remove timeout at all
  TRIA_CLIENT_HTTP_TIMEOUT: Number(envs.TRIA_CLIENT_HTTP_TIMEOUT) || 10000,

  // This variable is used by Firebase SDK to set fetch interval.
  // By default = 1 minute for development environment and 1 hour for production
  TRIA_CLIENT_FIREBASE_FETCH_INTERVAL:
    Number(envs.TRIA_CLIENT_FIREBASE_FETCH_INTERVAL) ||
    (process.env.NODE_ENV === 'development' ? ONE_MINUTE_MS : ONE_HOUR_MS),

  // This variable is used by Sentry SDK to capture application errors.
  TRIA_CLIENT_SENTRY_DSN: envs.TRIA_CLIENT_SENTRY_DSN,

  // This variable is used by debug Sentry.
  // Could be changed ONLY via `DEBUG`
  TRIA_CLIENT_SENTRY_DEBUG: false,

  // This variable is used by debug adapter.
  // Could be changed ONLY via `DEBUG`
  TRIA_CLIENT_ADAPTER_DEBUG: false,

  // This variable is used by debug player.
  // Could be changed ONLY via `DEBUG`
  TRIA_CLIENT_PLAYER_DEBUG: false,

  // This variable is used by bypass TrueID sign-in.
  // Could be changed ONLY via `DEBUG`
  TRIA_CLIENT_FORCE_REGULAR_SIGNIN: false,
} as const

// export debug modifier for debug functionality
// this allows to "change" environment variables in browser devtools
window.DEBUG = debugProxy(env)
