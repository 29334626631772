import { COLOR } from '@setplex/arcana'
import type { AppearanceStyle, AppearanceStyleSet } from '@setplex/tria-api'
import type { AppThemeFormatted } from './index.h'

const formatColorConfiguration = (style: AppearanceStyle) => {
  if (style.type === 'COLOR') {
    return style.color
  }

  if (style.type === 'GRADIENT') {
    const { angle, list } = style.configuration

    const colors = list.map(
      (colorConfig) => `${colorConfig.color} ${colorConfig.percentage}%`
    )
    return `linear-gradient(${angle}deg, ${colors.join(', ')})`
  }

  // in case of UNSET | IMAGE | undefined
  return null
}

export const formatAppTheme = (
  theme: AppearanceStyleSet
): React.CSSProperties => {
  const { mainStyle, additionalStyle, backgroundStyle, secondaryStyle } = theme

  const formattedTheme: AppThemeFormatted = {
    primaryColor: formatColorConfiguration(mainStyle),
    tertiaryColor: formatColorConfiguration(additionalStyle),
    backgroundColor: formatColorConfiguration(backgroundStyle),
    secondaryColor: formatColorConfiguration(secondaryStyle),
  }

  const { primaryColor, tertiaryColor, backgroundColor, secondaryColor } =
    formattedTheme

  return {
    [COLOR.ACCENT_PRIMARY_GRADIENT]: primaryColor,
    [COLOR.BUTTON_ACCENT_PRIMARY_GRADIENT]: primaryColor,
    [COLOR.CATEGORY_CARD_ACCENT_PRIMARY_GRADIENT]: primaryColor,
    [COLOR.ACCENT_TERTIARY]: tertiaryColor,
    [COLOR.BACKGROUND_DARK_APP]: backgroundColor,
    [COLOR.ACCENT_SECONDARY]: secondaryColor,
    [COLOR.PROGRESS_BAR_ACCENT_SECONDARY]: secondaryColor,
  } as React.CSSProperties
}
