import { model as config, remote } from '@/config'
import type { MeterProvider } from '@opentelemetry/sdk-metrics'
import {
  createEffect,
  createEvent,
  createStore,
  sample,
  type Store,
} from 'effector'
import type { Collector } from '../index.h'
import {
  haltTelemetry,
  initTelemetry,
  logger,
  parseCollectorUrl,
  verifyCollector,
} from '../lib'

export const init = createEvent<void>()
export const halt = createEvent<void>()

const $collectorUrl: Store<string | null> = config //
  .get(remote.tria_EXPERIMENTAL_openTelemetryMetricsCollector)

const $collector = createStore<Collector | null>(null) //
  .on($collectorUrl, (_, url) => parseCollectorUrl(url))

// meter provider to create meters across the application
export const $meterProvider = createStore<MeterProvider | null>(null)

//
// effects
//

const initTelemetryFx = createEffect(initTelemetry)
const haltTelemetryFx = createEffect(haltTelemetry)
const verifyCollectorFx = createEffect(verifyCollector)

//
// conditionally initialize or halt opentelemetry
//

sample({
  clock: $collector,
  filter: (collector) => collector == null,
  target: halt,
})

sample({
  clock: $collector,
  filter: (collector: Collector | null): collector is Collector =>
    collector != null && collector.valid,
  target: verifyCollectorFx,
})

sample({
  clock: verifyCollectorFx.done,
  target: [halt, init],
})

sample({
  clock: verifyCollectorFx.fail,
  target: halt,
})

sample({
  clock: verifyCollectorFx.failData,
  source: $collector,
  fn: (collector, fail) =>
    ({ ...collector, valid: false, error: String(fail) }) as Collector,
  target: $collector,
})

sample({
  clock: verifyCollectorFx.done,
  source: $collector,
  fn: (collector) => ['collector url:', collector!.url],
  target: logger.debugFx,
})

sample({
  clock: $collector,
  filter: (collector: Collector | null): collector is Collector =>
    collector != null && !collector.valid,
  fn: (collector) => ['invalid collector url', collector.error],
  target: logger.errorFx,
})

//
// init opentelemetry
//

sample({
  clock: init,
  source: $collector,
  filter: (collector: Collector | null): collector is Collector =>
    collector != null && collector.valid,
  target: initTelemetryFx,
})

sample({
  clock: initTelemetryFx.done,
  fn: () => ['initialized successfully'],
  target: logger.debugFx,
})

sample({
  clock: initTelemetryFx.fail,
  fn: (fail) => ['initialization error', fail],
  target: logger.errorFx,
})

sample({
  clock: initTelemetryFx.doneData,
  target: $meterProvider,
})

//
// halt opentelemetry
//

sample({
  clock: halt,
  source: $meterProvider,
  filter: Boolean,
  target: haltTelemetryFx,
})

sample({
  clock: haltTelemetryFx,
  fn: () => null,
  target: $meterProvider,
})

sample({
  clock: haltTelemetryFx,
  fn: () => ['halted'],
  target: logger.debugFx,
})
