import { Logger } from '@/logger'
import { type Formatter } from 'i18next'

export const logger = new Logger('tria/i18n')

type CapitalizeFormatterType = Parameters<Pick<Formatter, 'add'>['add']>[1]

export const capitalizeFormatter: CapitalizeFormatterType = (value) => {
  if (typeof value !== 'string') return value

  return value.replace(/[^\d\s]/, (letter) => letter.toUpperCase())
}
