import { sample } from 'effector'
import * as http from './http'
import { $meterProvider } from './telemetry'

export * as http from './http'
export * from './telemetry'

//
// init metrics,
// when provider is ready
//

sample({
  clock: $meterProvider,
  filter: (provider) => provider != null,
  target: [http.halt, http.init],
})

//
// halt metrics,
// when provider is absent
//

sample({
  clock: $meterProvider,
  filter: (provider) => provider == null,
  target: [http.halt],
})
