import { model as config, remote } from '@/config'
import { api, type AccountAccessToken } from '@setplex/tria-api'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { openWindow } from '~/core/router'
import { model as session } from '~/entities/session'
import { getSubscriberAccountSettingsUrl } from './helpers'

export const getPortalAccountAccessTokenFx = attach({
  effect: api.subscriber.getAccountAccessTokenFx,
})

export const $remoteSubscriberAccountSettingsURL = config.get(
  remote.tria_subscriberAccountSettingsUrl
)

export const openAccountSettingsModal = createEvent()
export const closeAccountSettingsModal = createEvent()
export const $isAccountSettingsModalOpened = createStore<boolean>(false)
  .on(openAccountSettingsModal, () => true)
  .on(closeAccountSettingsModal, () => false)

const $accountAccessToken = createStore<AccountAccessToken | null>(null)

const redirectToAccountSettingsFx = createEffect((url: string | null) => {
  if (url) {
    const handler = openWindow(url, 'external_portal')
    if (handler) return handler
  }
  throw new Error('Failed to open window')
})

sample({
  clock: openAccountSettingsModal,
  target: getPortalAccountAccessTokenFx,
})

sample({
  clock: getPortalAccountAccessTokenFx.doneData,
  target: $accountAccessToken,
})
sample({
  clock: getPortalAccountAccessTokenFx.failData,
  fn: () => null,
  target: $accountAccessToken,
})

export const navigateToAccountSettings = createEvent<void>()

sample({
  clock: navigateToAccountSettings,
  source: {
    token: $accountAccessToken,
    baseUrl: $remoteSubscriberAccountSettingsURL,
    session: session.$session,
  },
  fn: ({ baseUrl, session, token }) =>
    getSubscriberAccountSettingsUrl({ baseUrl, session, token }),
  target: redirectToAccountSettingsFx,
})
