import { combine, sample } from 'effector'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import './debug'
import { defaults } from './defaults'
import { $config, $ready } from './model'
import * as updates from './updates'

export { remote } from './defaults'
export * as model from './model'

// setup remote config updates
sample({
  clock: $ready,
  filter: Boolean,
  target: updates.init,
})

// export firebase remote config values as static variable,
// in case it is needed somewhere in imperative logic.
// ⚠️ DO NOT ABUSE
export const config = Object.assign(Object.create(null), defaults)

// this is a little escape hatch to keep `config` object
// up-to-date with firebase remote config values.
// on each update it will "evolve" the `config` object,
// like growing a new layer with new values on top of it.
// eslint-disable-next-line effector/no-watch
combine({
  config: $config,
  properties: firebase.analytics.$customParams,
}).updates.watch(({ config: updated, properties }) => {
  // keep properties always on top level
  delete config.__properties__

  // copy all current values to a new prototype
  const prototype = Object.assign(
    Object.create(Object.getPrototypeOf(config)),
    config
  )

  // delete all current values from config
  for (const key in config) {
    if (Object.prototype.hasOwnProperty.call(config, key)) {
      delete config[key]
    }
  }

  // assign new values to config
  Object.assign(config, updated, { __properties__: properties })

  // set new prototype
  // it is possible to create a new object, instead of modifying prototype,
  // but we need to keep const reference to the `config` object...
  Object.setPrototypeOf(config, prototype)
})
