import { noop } from '@/effector'
import type { Session } from '@setplex/tria-api'
import {
  attach,
  combine,
  createEvent,
  createStore,
  restore,
  sample,
  scopeBind,
  split,
} from 'effector'
import { persist } from 'effector-storage/session'
import { EventSource } from 'eventsource'
import { model as config, remote } from '~/shared/config'
import { holdup } from '~/shared/effector'
import { SUBSCRIBE, type AnyEvent } from './index.h'
import {
  isDataEvent,
  isSystemEvent,
  isSystemEventClose,
  isSystemEventConnected,
  isSystemEventPing,
  isSystemEventReconnect,
  logger,
} from './lib'

export const init = createEvent()

export const session = createEvent<Session | null>()
const connect = createEvent()
const close = createEvent()

export const message = createEvent<AnyEvent>()
const error = createEvent<Event>()

const $enabled = config.get(remote.tria_isServerSentEventsEnabled)

const $session = restore(session, null)
const $deviceIdentifier = $session.map(
  (session) => session?.deviceIdentifier || null
)

split({
  source: combine([$deviceIdentifier, $enabled]),
  match: ([device, enabled]) => (device && enabled ? 'connect' : 'close'),
  cases: { connect, close },
})

/*
 * prefiltered events' payloads
 */

const { systemEvent, dataEvent } = split(message, {
  systemEvent: isSystemEvent,
  dataEvent: isDataEvent,
})

export const systemMessage = systemEvent.map((event) => event.data)
export const dataMessage = dataEvent.map((event) => event.data)

const $lastEventId = createStore<string | null>(null) //
  .on(dataEvent, (_, event) => event.id)

// we do not use scopes for now, so, ignore this rule
// eslint-disable-next-line effector/require-pickup-in-persist
persist({
  store: $lastEventId,
  key: 'tria__last-event-id',
  fail: noop,
})

/*
 * manage sse connection and subscriptions
 */

const $eventSource = createStore<EventSource | null>(null)

// open connection
const openFx = attach({
  source: [$deviceIdentifier, $lastEventId],
  async effect([deviceIdentifier, lastEventId]) {
    await closeFx()

    const url = `/wbs/web/api/v3/devices/${deviceIdentifier}/messages`
    return new EventSource(url, {
      fetch(input, init) {
        const headers = init?.headers || {}
        if (lastEventId) {
          headers['Last-Event-Id'] = lastEventId
        }
        return fetch(input, { ...init, headers })
      },
    })
  },
})

// close connection
const closeFx = attach({
  source: $eventSource,
  effect(eventSource) {
    if (eventSource != null && eventSource.readyState !== EventSource.CLOSED) {
      eventSource.close()
    }
  },
})

// subscribe to errors and messages
const subscribeFx = attach({
  source: $eventSource,
  effect(eventSource) {
    if (eventSource == null) {
      throw new Error('SSE is not ready')
    }

    const err = scopeBind(error, { safe: true })
    eventSource.addEventListener('error', err)

    const msg = scopeBind(message, { safe: true })
    for (const type of SUBSCRIBE) {
      eventSource.addEventListener(type, (ev) => {
        let data = ev.data
        if (data != null) {
          try {
            // TODO: add contract validation library - https://setplexapps.atlassian.net/browse/FP-2487
            data = JSON.parse(data)
          } catch (e) {
            logger.error(`invalid "${type}" event data: ${data}`, e)
          }
        }
        msg({ event: type, data, id: ev.lastEventId })
      })
    }
  },
})

/// open connection

sample({
  clock: connect,
  target: openFx,
})

sample({
  clock: openFx.doneData,
  target: $eventSource,
})

sample({
  clock: openFx.fail,
  fn: (fail) => ['could not open connection:', fail],
  target: logger.errorFx,
})

/// close connection

sample({
  clock: close,
  target: closeFx,
})

sample({
  clock: [closeFx.finally, openFx.fail],
  fn: () => null,
  target: $eventSource,
})

sample({
  clock: closeFx.fail,
  fn: (fail) => ['could not close connection:', fail],
  target: logger.errorFx,
})

/// manage subscriptions and unsubscriptions

sample({
  clock: $eventSource,
  filter: Boolean,
  target: subscribeFx,
})

sample({
  clock: subscribeFx.fail,
  fn: (fail) => ['could not subscribe to events:', fail],
  target: logger.errorFx,
})

sample({
  clock: error,
  fn: (fail) => ['error:', fail],
  target: logger.errorFx,
})

/*
 * handle system messages
 */

const {
  closeSystemEvent,
  // connectedSystemEvent,
  // pingSystemEvent,
  reconnectSystemEvent,
} = split(systemMessage, {
  closeSystemEvent: isSystemEventClose,
  connectedSystemEvent: isSystemEventConnected,
  pingSystemEvent: isSystemEventPing,
  reconnectSystemEvent: isSystemEventReconnect,
})

/// close connection on close event

sample({
  clock: closeSystemEvent,
  target: close,
})

/// reconnect on RECONNECT event
/// (TODO) reconnect if no PING event received in 5 minutes
/// (TODO) reconnect if no CONNECTED event received in 1 minute

const delayedConnect = holdup({
  target: connect,
  cancel: [close, connect],
})

sample({
  clock: reconnectSystemEvent,
  fn: (msg) => msg.retry || 0,
  target: [close, delayedConnect],
})
